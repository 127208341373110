<template>
  <div id="try">
    <div class="part1">
      <b-img class="logo" @click="toIndex" :src="require('../../assets/img/index/logo.png')"></b-img>
    </div>
    <div class="part2">
      <div class="left">
        <div class="power">
          <div class="title">测量大师赋能经销商提升核心竞争力</div>
          <div class="content">
            <div class="item">
              <b-img :src="require('../../assets/img/try/icon1.png')"></b-img>
              <div class="item_content">
                <div class="item_title">10年+行业服务经验</div>
                <div class="item_des">创始团队身处一线，充分了解门窗、全屋定制行业经销商的痛点且拥有本行业十多年的IT软件经验</div>
              </div>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/try/icon2.png')"></b-img>
              <div class="item_content">
                <div class="item_title">365天1对1专业指导</div>
                <div class="item_des">365天1对1专业顾问指导，帮助经销商提升核心竞争力，帮助成功。</div>
              </div>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/try/icon3.png')"></b-img>
              <div class="item_content">
                <div class="item_title">高性价比更具选择优势</div>
                <div class="item_des">请两个朋友在街头吃顿饭的价格，使用后带来的不仅是便利，更是节省更大的成本。</div>
              </div>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/try/icon4.png')"></b-img>
              <div class="item_content">
                <div class="item_title">傻瓜式操作简单易懂</div>
                <div class="item_des">操作傻瓜简单易上手，在应用商店下载测量大师APP，开通账号即可使用。</div>
              </div>
            </div>

          </div>
        </div>
        <div class="company">
          <div class="title">行业标杆品牌在使用“测量大师”</div>
          <div class="content">
            <div class="item">
              <b-img :src="require('../../assets/img/logo/logo (1).png')"></b-img>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/logo/logo (2).png')"></b-img>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/logo/logo (3).png')"></b-img>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/logo/logo (4).png')"></b-img>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/logo/logo (5).png')"></b-img>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/logo/logo (6).png')"></b-img>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/logo/logo (7).png')"></b-img>
            </div>
            <div class="item">
              <b-img :src="require('../../assets/img/logo/logo (8).png')"></b-img>
            </div>
          </div>
        </div>
        <div class="call">
          <b-img :src="require('../../assets/img/try/phone.png')"></b-img>
          <div class="content">
            <div class="title">全国统一服务热线</div>
            <div class="phone">0510-85186908</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">申请体验</div>
        <div class="des">申请免费试用的资格</div>
        <b-form @submit.stop.prevent="submit">
          <b-form-group label="姓名" label-cols="3" label-cols-sm="2">
            <b-form-input v-model="$v.form.name.$model" :state="validateState('name')"
              aria-describedby="input-live-feedback" placeholder="怎么称呼您" trim></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              姓名不能为空
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="手机" label-cols="3" label-cols-sm="2">
            <b-form-input v-model="$v.form.tel.$model" :state="validateState('tel')"
              aria-describedby="input-2-live-feedback" placeholder="输入您的手机号" trim></b-form-input>
            <b-form-invalid-feedback id="input-2-live-feedback">
              请输入正确的手机号
            </b-form-invalid-feedback>

          </b-form-group>
          <b-form-group label="图像验证" label-cols="3" label-cols-sm="2">
            <b-form-input autocomplete="off" v-model="$v.form.captcha.$model" :state="validateState('captcha')"
              aria-describedby="input-live-feedback" placeholder="请输入图像验证码" trim></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              图像验证码不能为空
            </b-form-invalid-feedback>
            <div class="getcode1"><img :src="codeInfo.img" alt="" @click="getCodeInfo">
            </div>
            <!-- <b-form-input v-model="$v.form.code.$model" :state="validateState('captcha')"
              aria-describedby="input-3-live-feedback" placeholder="输入验证码" trim></b-form-input>
            <div class="getcode1"><img :src="codeInfo.img" alt="" @click="changeCodeInfo">
            </div> -->
          </b-form-group>
          <b-form-group label="验证码" label-cols="3" label-cols-sm="2">
            <b-form-input v-model="$v.form.code.$model" :state="validateState('code')"
              aria-describedby="input-3-live-feedback" placeholder="输入验证码" trim></b-form-input>
            <div v-show="show" @click="getcode" class="getcode">获取</div>
            <div v-show="!show" class="count">{{count}} s</div>
            <b-form-invalid-feedback id="input-3-live-feedback">
              验证码不能为空
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="描述" label-cols="3" label-cols-sm="2">
            <b-form-textarea v-model="$v.form.detail.$model" maxlength="500" :state="validateState('detail')"
              aria-describedby="input-5-live-feedback" placeholder="试用需求描述（选填）" rows="8" trim></b-form-textarea>
            <div class="tips"><span>{{number}}</span>/200</div>
            <b-form-invalid-feedback id="input-5-live-feedback">
              详细描述不能为空
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button type="submit" class="submit" variant="primary">立即提交</b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    validationMixin
  } from "vuelidate";
  import {
    required
  } from 'vuelidate/lib/validators'
  const myTel = (value) => {
    var telStr = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
    return telStr.test(value)
  }
  export default {
    name: "try",
    mixins: [validationMixin],
    data() {
      return {
        codeInfo: '',
        form: {
          captcha: '', //图像验证码
          name: "",
          tel: "",
          code: "",
          detail: ""
        },
        show: true,
        count: ""
      }
    },
    validations: {
      form: {
        name: {
          required
        },
        captcha: {
          required
        },
        tel: {
          required,
          myTel
        },
        code: {
          required
        },
        detail: {

        }
      }
    },
    computed: {
      number() {
        if (this.form.detail.length <= 200) {
          return this.form.detail.length
        } else {

          return 200
        }
      }
    },
    created() {
      this.getCodeInfo()
    },
    methods: {
      // 获取图像验证码
      getCodeInfo() {
        //
        this.$axios.get(`${this.$Tools.Conts.domain}get-captcha`).then(res => {
          if (res.data.code == 0) {
            this.codeInfo = res.data.data
            // console.log(res.data.data)
          }
        })
      },
      toIndex() {
        this.$router.push({
          name: "Index"
        })
      },
      validateState(name) {
        const {
          $dirty,
          $error
        } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      submit() {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        let params = {
          name: this.form.name,
          phone: this.form.tel,
          content: this.form.detail,
          code: this.form.code
        }
        this.$axios.post(`${this.$Tools.Conts.domain}official/trials`, params).then(res => {
          if (res.data.code == 0) {
            this.$toast.success({
              message: res.data.message,
              timeOut: 2000
            })
          } else {
            this.$toast.warn({
              message: res.data.message,
              timeOut: 2000
            })
          }
        })
      },
      getcode() {
        var telStr =
          /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
        if (!telStr.test(this.form.tel)) {
          this.$toast.warn({
            message: "请先填写正确的手机号",
            timeOut: 2000
          })
          return false;
        }
        if (!this.form.captcha) {
          return this.$toast.warn({
            message: "请先填写图像验证码",
            timeOut: 2000
          })
        }

        let verify_token = '';
        let phone = this.form.tel + 'VbmCv4jJCsj22RBPSwKI9IFIdG5+FRGABX+aPFFC9MY='
        phone = this.$md5(phone)
        verify_token = phone
        let params = {
          captcha: this.form.captcha,
          type: '16',
          verify_token: verify_token,
          key: this.codeInfo.key
        }

        this.$axios.get(`${this.$Tools.Conts.domain}verify-code/${this.form.tel}`, {
          params
        }).then(res => {
          if (res.data.code == 0) {
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.show = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
            this.$toast.success({
              message: '验证码发送成功',
              timeOut: 2000
            })
          } else {
            this.$toast.warn({
              message: res.data.message,
              timeOut: 2000
            })
          }
        })
      }
    },
    watch: {

    }
  }
</script>

<style lang="less" scoped>
  #try {
    background: url("../../assets/img/try/bac.png") no-repeat;
    background-size: cover;
    min-height: 100vh;
    padding-bottom: 4rem;

    .part1 {
      padding: 2rem 0;
      width: 90%;
      margin: auto;

      .logo {
        width: 120px;
        cursor: pointer;
        margin-left: 0rem;
      }
    }

    .part2 {
      background: rgba(255, 255, 255, 0.9);
      width: 90%;
      border-radius: 6px;
      margin: auto;
      padding: 2rem 0;
      display: flex;
      flex-flow: column;

      .left {
        flex: 65%;
        border-bottom: 1px solid #c4c4c4;
        padding: 0 2rem 2rem 2rem;

        .power {
          .title {
            font-size: 1.2rem;
            color: #2b85e4;
            padding-bottom: 1.5rem;
          }

          .content {
            display: flex;
            flex-wrap: wrap;

            .item {
              flex: 100%;
              padding: 0 0 1.5rem 0;
              display: flex;

              img {
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                flex-shrink: 0;
                margin-right: 1rem;
              }

              .item_content {
                .item_title {
                  font-size: 1rem;
                  padding-bottom: 0.2rem;
                }

                .item_des {
                  font-size: 0.7rem;
                  color: gray;
                }
              }
            }
          }
        }

        .company {
          padding-bottom: 1rem;

          .title {
            font-size: 1.2rem;
            padding-bottom: 1rem;
          }

          .content {
            display: flex;
            flex-wrap: wrap;

            .item {
              margin: 0 0.5rem 0.5rem 0;

              width: calc(50% - 0.5rem);

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .call {
          display: flex;

          img {
            width: 3rem;
            height: 3rem;
            border-radius: 50%;
            margin-right: 0.5rem;
          }

          .content {
            .title {
              font-size: 0.8rem;
              color: gray;
            }

            .phone {
              font-size: 1.2rem;
            }
          }
        }
      }

      .right {
        flex: 35%;
        padding: 0 2rem 0 1rem;

        .title {
          font-size: 1.8rem;
          text-align: center;
          padding: 2rem 0 0;
        }

        .des {
          font-size: 1rem;
          text-align: center;
          color: gray;
          padding-bottom: 2rem;
        }

        form {
          /deep/ .form-row {
            .col-form-label {
              font-size: 0.9rem;
              text-align: right;
            }

            input,
            select,
            textarea {
              font-size: 0.9rem;

              &::placeholder {
                color: #999999;
              }
            }

            .getcode {
              width: 50px;
              font-size: 15px;
              color: #2b85e4;
              position: absolute;
              right: 15px;
              top: 6px;
              cursor: pointer;
            }

            .count {
              width: 50px;
              font-size: 15px;
              color: red;
              position: absolute;
              right: 15px;
              top: 6px;
            }

            .tips {
              position: absolute;
              right: 1rem;
              bottom: 0.2rem;
              color: gray;
            }
          }

          .submit {
            font-size: 0.9rem;
            padding: 0.7rem 0;
            width: 75%;
            margin-left: 25%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 576px) {
    #try {
      background: url("../../assets/img/try/bac.png") no-repeat;
      background-size: cover;
      min-height: 100vh;
      padding-bottom: 4rem;

      .part1 {
        padding: 2rem 0;
        width: 85%;
        margin: auto;

        .logo {
          width: 120px;
          cursor: pointer;
          margin-left: -3rem;
        }
      }

      .part2 {
        background: rgba(255, 255, 255, 0.9);
        width: 85%;
        border-radius: 6px;
        margin: auto;
        padding: 3.5rem 0;
        display: flex;
        flex-flow: inherit;

        .left {
          flex: 65%;
          padding: 0 2rem 2rem 3rem;
          border-right: 1px solid #c4c4c4;
          border-bottom: none;

          .power {
            .title {
              font-size: 1.3rem;
              color: #2b85e4;
              padding-bottom: 2.63rem;
            }

            .content {
              display: flex;
              flex-wrap: wrap;

              .item {
                flex: 50%;
                padding: 0 3.13rem 2.13rem 0;
                display: flex;

                img {
                  width: 4rem;
                  height: 4rem;
                  border-radius: 50%;
                  flex-shrink: 0;
                  margin-right: 1rem;
                }

                .item_content {
                  .item_title {
                    font-size: 1.1rem;
                    padding-bottom: 0.2rem;
                  }

                  .item_des {
                    font-size: 0.9rem;
                    color: gray;
                  }
                }
              }
            }
          }

          .company {
            padding-bottom: 1.81rem;

            .title {
              font-size: 1.3rem;
              padding-bottom: 2.31rem;
            }

            .content {
              display: flex;
              flex-wrap: wrap;

              .item {
                margin: 0 0.5rem 0.5rem 0;
                width: 20%;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .call {
            display: flex;

            img {
              width: 3.75rem;
              height: 3.75rem;
              border-radius: 50%;
              margin-right: 0.63rem;
            }

            .content {
              .title {
                font-size: 1rem;
                color: #333333;
              }

              .phone {
                font-size: 1.5rem;
              }
            }
          }
        }

        .right {
          flex: 35%;
          padding: 0 3rem 0 1rem;

          .title {
            font-size: 1.8rem;
            text-align: center;
            padding: 2rem 0 0;
          }

          .des {
            font-size: 1rem;
            text-align: center;
            color: gray;
            padding-bottom: 2rem;
          }

          form {
            /deep/ .form-row {
              .col-form-label {
                font-size: 0.9rem;
                text-align: right;
              }

              input,
              select,
              textarea {
                font-size: 0.9rem;

                &::placeholder {
                  color: #999999;
                }
              }

              .getcode {
                width: 50px;
                font-size: 15px;
                color: #2b85e4;
                position: absolute;
                right: 15px;
                top: 6px;
                cursor: pointer;
              }

              .count {
                width: 50px;
                font-size: 15px;
                color: red;
                position: absolute;
                right: 15px;
                top: 6px;
              }

              .tips {
                position: absolute;
                right: 1rem;
                bottom: 0.2rem;
                color: gray;
              }
            }

            .submit {
              font-size: 0.9rem;
              padding: 0.7rem 0;
              width: 83.3333333%;
              margin-left: 16.6666666%;
            }
          }
        }
      }
    }

    .getcode1 {
      width: 200px;
      font-size: 15px;
      color: rgba(45, 140, 240, 1);
      cursor: pointer;
    }
  }

  @media screen and (min-width: 1300px) and (max-width: 1550px) {
    #try {
      padding-bottom: 1rem;

      .part1 {
        padding: 1.5rem 0;
      }

      .part2 {
        padding: 2rem 0 2rem;

        .left {
          padding: 0 2rem 0rem 3rem;

          .power {
            .title {
              padding-bottom: 1.8rem;
            }

            .content {
              .item {
                padding: 0 3.13rem 1.5rem 0;
              }
            }
          }

          .company {
            padding-bottom: 1.4rem;

            .title {
              padding-bottom: 1.8rem;
            }
          }
        }
      }
    }
  }
</style>